import React, { useState, useEffect, useRef } from "react";
import HighlightsRisk from "../../components/highlights-risk/HighlightsRisk";
import OptionsSection from "../../components/options-section/OptionsSection";
import { ReactComponent as DataProcess } from "../../assets/images/data-process.svg";
import { ReactComponent as NoIssue } from "../../assets/images/completed.svg";
import { ReactComponent as Folder } from "../../assets/images/folder.svg";
import axios from "../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import BreadCrumb from "../../components/breadcrumbs/BreadCrumb";
import { useParams, useLocation } from "react-router-dom";
import GenericCard from "../../components/card/GenericCard.js";
import IssueStatusChart from "../../components/charts/IssueStatusChart";
import { Chart } from "chart.js";
import { useMemo } from "react";
import GroupSwitch from "../../components/cves/Switch";
import CveList from "./CveList";
import { webUpdatesFilter } from "../../util/genericFunctions.js";
import GenericActionModal from "./GenericActionModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  impacts,
  Exploitabilities,
  CVSS,
  EPSS,
  scores,
  hasCveFilterOptions,
  serviceMap,
  openColorList,
  historycolorList,
} from "./filterOptions.js";
const OpenIssues = ({ type, updateScanningDetails, activeTab }) => {
  const timeoutRef = useRef(null);
  const routeParams = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ipParam = searchParams.get("ip");
  const scannerParam = searchParams.get("scanner");
  const armoryIdParam = searchParams.get("armoryIdParam");
  const domainParam = searchParams.get("domain");
  const hostParam = searchParams.get("host");
  const portParam = searchParams.get("port");
  const issueParam = searchParams.get("issue");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cves, setCves] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isSortBy, setIsSortBy] = useState("rhl");
  const [isFinished, setIsFinished] = useState(false);
  const [selectedSortValue, setSelectedSortValue] = useState(
    "Risk Score High to Low"
  );
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(null);
  const [originalCves, setOriginalCves] = useState([]); // Store the original data
  const [separatedCves, setSeparatedCves] = useState([]); // Store the separated results
  const [selectedIssueStatus, setSelectedIssueStatus] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [clearRowsTrigger, setClearRowsTrigger] = useState(0);
  const [modalData, setModalData] = useState(null); // Store modal data here
  const [showModal, setShowModal] = useState(false);

  const actions =
    type === "open"
      ? [
          { id: 1, name: "Solve" },
          { id: 2, name: "False-Positive" },
          { id: 3, name: "Acknowledge Risk" },
        ]
      : [{ id: 4, name: "Restore" }];

  const [doughnutChartType, setDoughnutChartType] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: openColorList,
        hoverBackgroundColor: openColorList,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  });

  const centerTextPlugin = {
    id: "centerText",
    afterDraw: function (chart) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      const totalIssuesStatus =
        chart.config.options.plugins.centerText?.totalIssuesStatus;

      if (totalIssuesStatus !== undefined) {
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em Kodchasan`;
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#F5F5F5";

        const text = `${totalIssuesStatus}`; // Total value to display
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
  };

  Chart.register(centerTextPlugin);
  const totalIssuesStatus = doughnutChartType.datasets[0].data.reduce(
    (acc, val) => acc + val,
    0
  );
  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: function () {
            return ""; // Remove the top title
          },
          label: function (context) {
            const label = context.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "center",
        xAlign: "right",
      },
      title: {
        display: false,
      },
      centerText: {
        totalIssuesStatus: totalIssuesStatus,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: "transparent",
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
  };
  const [tabs, setTabs] = useState([]);
  const [assets, setAssets] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [collapsedGroups, setCollapsedGroups] = useState({});

  const sortData = (allCves, sortBy) => {
    switch (sortBy) {
      case "chl":
        return allCves.sort((a, b) => {
          const cvssA = a.cvss === "-" ? -Infinity : parseFloat(a.cvss);
          const cvssB = b.cvss === "-" ? -Infinity : parseFloat(b.cvss);
          return cvssB - cvssA;
        });
      case "clh":
        return allCves.sort((a, b) => {
          const cvssA = a.cvss === "-" ? -Infinity : parseFloat(a.cvss);
          const cvssB = b.cvss === "-" ? -Infinity : parseFloat(b.cvss);
          return cvssA - cvssB;
        });
      case "rhl":
        return allCves.sort((a, b) => b.risk_score - a.risk_score);
      case "rlh":
        return allCves.sort((a, b) => a.risk_score - b.risk_score);
      case "ihl":
        return allCves.sort((a, b) => b.impact - a.impact);
      case "ilh":
        return allCves.sort((a, b) => a.impact - b.impact);
      case "ehl":
        return allCves.sort((a, b) => b.exploitability - a.exploitability);
      case "elh":
        return allCves.sort((a, b) => a.exploitability - b.exploitability);
      case "ephl":
        return allCves.sort((a, b) => {
          const epssA = a.epss === "-" ? -Infinity : parseFloat(a.epss);
          const epssB = b.epss === "-" ? -Infinity : parseFloat(b.epss);
          return epssB - epssA;
        });
      case "eplh":
        return allCves.sort((a, b) => {
          const epssA = a.epss === "-" ? -Infinity : parseFloat(a.epss);
          const epssB = b.epss === "-" ? -Infinity : parseFloat(b.epss);
          return epssA - epssB;
        });
      default:
        return allCves;
    }
  };

  const handleSortChange = (e) => {
    setIsSortBy(e.value);
    setSelectedSortValue(e.label);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const updateTabsWithData = (cveData) => {
    let allAssets = cveData.reduce((acc, el) => {
      el.assets?.forEach((el) => {
        acc.push(
          `${
            el?.host
              ? el?.host
              : el?.domain
              ? el?.domain
              : el.ip && el.port
              ? `${el.ip} Port: ${el.port}`
              : el.ip
              ? el.ip
              : "-"
          }`
        );
      });
      return acc;
    }, []);

    const uniqueAssets = Array.from(new Set(allAssets)).map((name, index) => ({
      id: index,
      name: name || "-",
      type: name ? "Asset: " + name : "Asset: -",
      key: "2",
      active: false,
    }));

    const issueNames = Array.from(
      new Set(cveData.map((cve) => cve.issue_name))
    ).map((name, index) => ({
      id: index,
      name: name ? name : "-",
      type: name ? "Issue Name: " + name : "Issue Name: -",
      key: "1",
      active: false,
    }));

    const categories = Array.from(
      new Set(cveData.map((cve) => cve.scan_category))
    ).map((category, index) => ({
      id: index,
      name: category ? category : "-",
      type: category ? "Category: " + category : "Category: -",

      key: "3",
      active: false,
    }));
    const uniqueIps = Array.from(
      new Set(
        cveData
          .flatMap((cve) => cve.assets?.map((asset) => asset.ip) || [])
          .filter((ip) => ip)
      )
    ).map((ip) => ({
      id: ip,
      name: `${ip}`,
      type: `IP: ${ip}`,
      key: "ip",
      active: false,
    }));
    const scanners = Array.from(
      new Set(cveData.map((scan) => scan.service_id))
    ).map((scan, index) => {
      const service = serviceMap.find((s) => s.id === scan);
      return {
        id: scan,
        name: service ? service.serviceName : "-",
        type: service ? `Scanner: ${service.serviceName}` : "Scanner: -",
        key: "scanner",
        active: false,
      };
    });

    setTabs([
      {
        eventKey: "1",
        title: "Security Issue",
        contentTitle: "Security Issue",
        data: Array.from(issueNames),
      },
      {
        eventKey: "2",
        title: "Relevant Assets",
        contentTitle: "Relevant Assets",
        data: Array.from(uniqueAssets),
      },

      {
        eventKey: "3",
        title: "Category",
        contentTitle: "Category",
        data: Array.from(categories),
      },
      {
        eventKey: "4",
        title: "Impact",
        contentTitle: "Impact",
        data: Array.from(impacts),
      },
      {
        eventKey: "5",
        title: "Exploitability",
        contentTitle: "Exploitability",
        data: Array.from(Exploitabilities),
      },
      {
        eventKey: "6",
        title: "CVSS",
        contentTitle: "CVSS",
        data: Array.from(CVSS),
      },
      {
        eventKey: "7",
        title: "EPSS",
        contentTitle: "EPSS",
        data: Array.from(EPSS),
      },
      {
        eventKey: "8",
        title: "Risk Score",
        contentTitle: "Risk Score",
        data: Array.from(scores),
      },
      {
        eventKey: "9",
        title: "Has CVE",
        contentTitle: "Has CVE",
        data: Array.from(hasCveFilterOptions),
      },
      {
        eventKey: "ip",
        title: "IP",
        contentTitle: "IP",
        data: Array.from(uniqueIps),
      },
      {
        eventKey: "scanner",
        title: "Scanner",
        contentTitle: "Scanner",
        data: Array.from(scanners),
      },
    ]);
  };

  const fetchCves = async (id) => {
    setIsLoading(true);
    const endpoint =
      type === "open" ? `/issues/${id}/open` : `/issues/${id}/history`;
    try {
      const {
        data: {
          results = [],
          scanning,
          completionTime,
          items = [],
          countGroups,
        },
      } = await axios.get(endpoint);
      const originalData = results;
      if (results.length === 0 && items?.length === 0) {
        setOriginalCves([]);
        setSeparatedCves([]);
        setCves([]);
        updateTabsWithData([]);
        setIsLoading(false);
        return;
      }
      setOriginalCves(originalData);
      setSeparatedCves(items);

      const filteredData = ipParam
        ? items.filter((cve) => cve.ip === ipParam)
        : items;
      if (portParam) {
        handleIpPortParamClick(ipParam, armoryIdParam, portParam, originalData);
      } else {
        handleIpParamClick(ipParam, armoryIdParam, portParam, originalData);
      }
      handleDomainParamClick(domainParam, armoryIdParam, originalData);
      handleHostParamClick(hostParam);
      handleScannerServiceClick(scannerParam);
      if (issueParam) {
            handleIssueParamClick(issueParam, originalData);
      }

      if (type === "open") {
        const labels = ["Open", "Re-open", "Pending Validation"];
        const data = [
          countGroups.open || 0,
          countGroups.reopen || 0,
          countGroups.pv || 0,
        ];

        if (countGroups.vf) {
          labels.push("Validation Failed");
          data.push(countGroups.vf);
        }

        setDoughnutChartType({
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: openColorList,
              hoverBackgroundColor: openColorList,
            },
          ],
        });
      } else if (type === "history") {
        setDoughnutChartType({
          labels: ["Solved", "Acknowledged", "False-Positive"],
          datasets: [
            {
              data: [
                countGroups.solved || 0,
                countGroups.ack || 0,
                countGroups.fp || 0,
              ],
              backgroundColor: historycolorList,
              hoverBackgroundColor: historycolorList,
            },
          ],
        });
      }
      setCves(filteredData);
      updateTabsWithData(originalData);
      setIsLoading(false);
      updateScanningDetails({
        status: scanning,
        date: completionTime,
      });
    } catch (err) {
      if (err?.response?.data?.status == false) {
        setIsFinished(false);
        updateScanningDetails({
          status: "Temporary error",
          date: new Date(),
        });
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (routeParams?.id) {
      fetchCves(routeParams.id);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [routeParams.id, scannerParam, type]);
  const filterCategories = [
    { key: "2", data: assets, field: "assets", isAsset: true },
    { key: "4", data: impacts, field: "impact" },
    { key: "5", data: Exploitabilities, field: "exploitability" },
    { key: "6", data: CVSS, field: "cvss" },
    { key: "7", data: EPSS, field: "epss" },
    { key: "8", data: scores, field: "risk_score" },
  ];

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const statusMap = {
    Open: { value: 1 },
    "Re-open": { value: 2 },
    "Pending Validation": { value: 3 },
    "Validation Failed": { value: 4 },
    Acknowledged: { value: 7 },
    Solved: { value: 5 },
    "False-Positive": { value: 6 },
  };

  const filteredCves = useMemo(() => {
    // Select data based on isChecked
    const dataToFilter = isChecked ? originalCves : separatedCves;

    // Return the full data set if no active filters or search value is applied
    if (!activeFilters.length && searchValue === "") {
      return sortData(dataToFilter, isSortBy); // Apply sorting here
    }

    // Filter the data based on active filters and search value
    let filteredData = dataToFilter.filter((cve) => {
      // Apply active filters
      const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
        return groupedFilters[eventKey].some((filter) => {
          if (eventKey === "1") {
            return (
              (filter.name === "-" &&
                (!cve.issue_name || cve.issue_name === "-")) ||
              (cve.issue_name &&
                cve.issue_name.toLowerCase() === filter.name.toLowerCase())
            );
          } else if (eventKey === "3") {
            return (
              (filter.name === "-" &&
                (!cve.scan_category || cve.scan_category === "-")) ||
              (cve.scan_category &&
                cve.scan_category.toLowerCase() === filter.name.toLowerCase())
            );
          } else if (eventKey === "9") {
            return (
              (filter.name.toLowerCase() === "no" && !cve.cve) ||
              (filter.name.toLowerCase() === "yes" && cve.cve)
            );
          } else if (eventKey === "scanner") {
            const service = serviceMap.find(
              (s) => s.serviceName === filter.name
            );
            return service ? cve.service_id === service.id : false;
          } else if (eventKey === "8") {
            const filterName = filter.name;
            const match = filterName.match(/(\d+)-(\d+)/);
            if (match) {
              const [min, max] = match.slice(1, 3).map(Number);
              return cve.risk_score >= min && cve.risk_score <= max;
            }
            return false;
          } else if (eventKey === "ip" || eventKey === "port") {
            const portParamString = portParam ? String(portParam) : null;

            if (portParam) {
              return (
                (String(cve.ip) === String(filter.name) &&
                  String(cve.port) === portParamString) ||
                cve.assets?.some(
                  (asset) =>
                    String(asset.ip) === String(filter.name) &&
                    String(asset.port) === portParamString
                )
              );
            } else {
              return (
                String(cve.ip) === String(filter.name) ||
                cve.assets?.some(
                  (asset) => String(asset.ip) === String(filter.name)
                )
              );
            }
          } else if (eventKey === "issueStatus") {
            const filterNameLowerCase = filter.name;
            const mappedStatus = statusMap[filterNameLowerCase]?.value;
            if (mappedStatus) {
              return isChecked
                ? cve.assets?.some(
                    (asset) => asset.issue_status === mappedStatus
                  )
                : cve.issue_status === mappedStatus;
            } else {
              return false;
            }
          } else if (eventKey === "domain") {
            return (
              cve.domain &&
              cve.domain.toLowerCase() === filter.name.toLowerCase()
            );
          } else if (eventKey === "host") {
            return (
              cve.host && cve.host.toLowerCase() === filter.name.toLowerCase()
            );
          } else {
            return filterCategories.some((category) => {
              if (eventKey === category.key) {
                if (category.field === "assets") {
                  return cve.assets?.some((asset) => {
                    let assetName = asset.ip ? asset.ip : "";
                    if (asset.port) {
                      assetName += ` Port: ${asset.port}`;
                    } else if (asset.domain) {
                      assetName = asset.domain;
                    } else if (asset.host) {
                      assetName = asset.host;
                    }
                    assetName = assetName.trim().toLowerCase();
                    const filterName = filter.name.trim().toLowerCase();
                    return assetName === filterName;
                  });
                } else {
                  const score = cve[category.field] || 0;
                  const [min, max] = filter.name.split("-").map(Number);
                  return score >= min && score <= max;
                }
              }
              return false;
            });
          }
        });
      });

      // Apply search value
      const matchesSearchValue =
        searchValue === "" ||
        (isChecked
          ? // Search within assets when isChecked is true
            cve.assets?.some(
              (el) =>
                (el.ip &&
                  el.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
                (el.port &&
                  String(el.port)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) ||
                (el.domain &&
                  el.domain
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) ||
                (el.host &&
                  el.host.toLowerCase().includes(searchValue.toLowerCase()))
            )
          : // Search within cve fields when isChecked is false
            (cve.issue_name &&
              cve.issue_name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (cve.scan_category &&
              cve.scan_category
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (cve.ip &&
              cve.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
            (cve.port &&
              String(cve.port)
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (cve.domain &&
              cve.domain.toLowerCase().includes(searchValue.toLowerCase())) ||
            (cve.host &&
              cve.host.toLowerCase().includes(searchValue.toLowerCase())));

      // Apply risk level filter
      const matchesRiskLevel =
        !selectedRiskLevel ||
        (selectedRiskLevel === "high" &&
          cve.risk_score >= 80 &&
          cve.risk_score <= 100) ||
        (selectedRiskLevel === "medium" &&
          cve.risk_score >= 40 &&
          cve.risk_score <= 79) ||
        (selectedRiskLevel === "low" &&
          cve.risk_score >= 0 &&
          cve.risk_score <= 39);

      // Apply issue status filter
      const matchesIssueStatus =
        !selectedIssueStatus ||
        (isChecked
          ? cve.assets?.some((asset) =>
              selectedIssueStatus?.includes(asset.issue_status)
            )
          : selectedIssueStatus?.includes(cve.issue_status));

      // Return only the CVEs that match filters, search, risk level, and issue status
      return (
        matchesFilters &&
        matchesSearchValue &&
        matchesRiskLevel &&
        matchesIssueStatus
      );
    });

    // Sort the filtered data
    return sortData(filteredData, isSortBy);
  }, [
    isChecked,
    separatedCves,
    originalCves,
    groupedFilters,
    searchValue,
    selectedRiskLevel,
    selectedIssueStatus,
    filterCategories,
    activeFilters,
    isSortBy,
  ]);

  let highRisk = 0;
  let mediumRisk = 0;
  let lowRisk = 0;
  cves?.map((el) => {
    if (el.risk_score >= 80 && el.risk_score <= 100) {
      highRisk += 1;
    } else if (el.risk_score >= 40 && el.risk_score <= 79) {
      mediumRisk += 1;
    } else if (el.risk_score >= 0 && el.risk_score <= 39) {
      lowRisk += 1;
    }
  });
  const handleRiskClick = (riskLevel) => {
    let riskFilterName = "";

    switch (riskLevel) {
      case "high":
        riskFilterName = "80-100";
        break;
      case "medium":
        riskFilterName = "40-79";
        break;
      case "low":
        riskFilterName = "0-39";
        break;
      default:
        riskFilterName = "";
    }
    setActiveFilters(
      webUpdatesFilter(riskFilterName, activeFilters, "8", "Risk Score")
    );
  };

  const handleSwitchChange = (e) => {
    const isSwitchChecked = e.target.checked;
    setIsChecked(isSwitchChecked);
    const newCves = isSwitchChecked ? originalCves : separatedCves;
    setCves(newCves);
    if (!isSwitchChecked) {
      setCollapsedGroups(newCves.map(() => true));
    }
  };
  const toggleCollapse = (groupName) => {
    setCollapsedGroups((prevState) => ({
      ...prevState,
      [groupName]: !prevState[groupName],
    }));
  };
  useEffect(() => {
    // Default all groups to collapsed (hidden)
    const initialCollapsedState = cves.reduce((acc, group) => {
      acc[group.issue_name] = false;
      return acc;
    }, {});
    setCollapsedGroups(initialCollapsedState);
  }, [cves]);

  const handleIssueClick = (issueStatus) => {
    setActiveFilters(
      webUpdatesFilter(
        issueStatus,
        activeFilters,
        "issueStatus",
        "Issue Status"
      )
    );
  };
  const handleIpPortParamClick = (ipParam, armoryIdParam, portParam, data) => {
    console.log("data-data", ipParam, portParam);
    if (
      (!ipParam && !armoryIdParam) ||
      !Array.isArray(data) ||
      data.length === 0
    ) {
      return;
    }

    const portParamNumber = portParam ? Number(portParam) : null;
    const filteredByParams = data.filter((cve) => {
      const cvePort = cve.port ? Number(cve.port) : null;

      const matchesIp =
        cve.ip === ipParam || cve.assets?.some((asset) => asset.ip === ipParam);

      const matchesPort =
        cvePort === portParamNumber ||
        cve.assets?.some((asset) => Number(asset.port) === portParamNumber);
      const matchesArmoryId = cve.armory_id === armoryIdParam;
      console.log("Checking IP:", matchesIp);
      console.log("Checking Port:", matchesPort);

      if (portParamNumber) {
        return matchesIp && matchesPort;
      } else {
        return armoryIdParam ? matchesArmoryId : matchesIp;
      }
    });

    const firstIssueName = filteredByParams.length
      ? filteredByParams[0].issue_name
      : null;

    setActiveFilters((currentFilters) => {
      const filteredFilters = currentFilters.filter(
        (filter) => filter.eventKey !== "ip" && filter.eventKey !== "1"
      );

      let updatedFilters = ipParam
        ? webUpdatesFilter(ipParam, filteredFilters, "ip", "Asset")
        : filteredFilters;

      if (firstIssueName && portParamNumber) {
        updatedFilters = webUpdatesFilter(
          firstIssueName,
          updatedFilters,
          "1",
          "Issue Name"
        );
      }

      return updatedFilters;
    });
  };

  const handleIpParamClick = (ipParam, armoryIdParam, portParam, data) => {
    if (
      (!ipParam && !armoryIdParam) ||
      !Array.isArray(data) ||
      data.length === 0
    ) {
      return;
    }

    const portParamNumber = portParam ? Number(portParam) : null;

    const filteredByParams = data.filter((cve) => {
      // Check IP and assets IP matches
      const matchesIp = ipParam
        ? cve.ip === ipParam ||
          cve.assets?.some((asset) => asset.ip === ipParam)
        : true;

      // Check if the armory ID matches
      const matchesArmoryId = armoryIdParam
        ? cve.armory_id === armoryIdParam
        : true;

      // Check if port number matches if provided
      const matchesPort =
        portParamNumber !== null
          ? cve.port === portParamNumber ||
            cve.assets?.some((asset) => asset.port === portParamNumber)
          : true; // Ignore port matching if not provided

      // Filter based on IP, Armory ID, and optionally port
      return matchesIp && matchesArmoryId && matchesPort;
    });

    const firstIssueName = filteredByParams.length
      ? filteredByParams[0].issue_name
      : null;

    // Update active filters based on IP and Armory ID
    setActiveFilters((currentFilters) => {
      const filteredFilters = currentFilters.filter(
        (filter) =>
          filter.eventKey !== "ip" &&
          filter.eventKey !== "armoryIdParam" &&
          filter.eventKey !== "1" // Remove existing issue_name filter
      );

      // Add the IP filter if available
      let updatedFilters = ipParam
        ? webUpdatesFilter(ipParam, filteredFilters, "ip", "Asset")
        : filteredFilters;
      // Add the Issue Name filter if present, regardless of the port number
      if (firstIssueName && armoryIdParam) {
        updatedFilters = webUpdatesFilter(
          firstIssueName,
          updatedFilters,
          "1",
          "Issue Name"
        );
      }

      return updatedFilters;
    });
  };

  const handleDomainParamClick = (domainParam, armoryIdParam, data) => {
    if (
      (!domainParam && !armoryIdParam) ||
      !Array.isArray(data) ||
      data.length === 0
    )
      return;
    const filteredByParams = data.filter((cve) => {
      const matchesDomain =
        cve.domain === domainParam ||
        cve.assets?.some((asset) => asset.domain === domainParam);
      const matchesArmoryId = cve.armory_id === armoryIdParam;
      return armoryIdParam ? matchesArmoryId : matchesDomain;
    });
    const issueNames = Array.from(
      new Set(filteredByParams.map((cve) => cve.issue_name).filter(Boolean))
    );
    setActiveFilters((currentFilters) => {
      const filteredFilters = currentFilters.filter(
        (filter) =>
          filter.eventKey !== "domain" &&
          filter.eventKey !== "armoryIdParam" &&
          filter.eventKey !== "1"
      );
      const updatedFilters = domainParam
        ? webUpdatesFilter(domainParam, filteredFilters, "domain", "Asset")
        : filteredFilters;
      return issueNames.reduce(
        (accumulatedFilters, issueName) =>
          webUpdatesFilter(
            `${issueName}`,
            accumulatedFilters,
            "1",
            "Issue Name"
          ),
        updatedFilters
      );
    });
  };

  const handleHostParamClick = (hostParam) => {
    if (!hostParam) return;
    setActiveFilters((currentFilters) =>
      webUpdatesFilter(
        hostParam,
        currentFilters.filter((filter) => filter.eventKey !== "host"),
        "host",
        "Asset"
      )
    );
  };

  const handleScannerServiceClick = (scannerParam) => {
    if (!scannerParam) return;
    const service = serviceMap.find((s) => s.id === parseInt(scannerParam, 10));

    if (service) {
      setActiveFilters((currentFilters) => {
        const filteredFilters = currentFilters.filter(
          (filter) => filter.eventKey !== "scanner"
        );
        const updatedFilters = webUpdatesFilter(
          `${service.serviceName}`,
          filteredFilters,
          "scanner",
          "Scanner"
        );
        return updatedFilters;
      });
    }
  };

  const handleIssueParamClick = (issueParam, data) => {
    if (!issueParam || !Array.isArray(data) || data.length === 0) return;
    let updatedFilters = webUpdatesFilter(issueParam, activeFilters, "1", "Issue Name");
    setActiveFilters(updatedFilters);
  };
  
  
  
  const handleClearSelectRow = () => {
    setSelectedItems([]);
    setClearRowsTrigger((prev) => prev + 1);
  };
  const handleActionClick = (actionType, data, type) => {
    setModalData({ actionType, data, type });
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setModalData(null);
  };
  const onConfirm = (value, type) => {
    if (value === 6 || value === 7) {
      activeTab("History");
    } else if (value === 2) {
      activeTab("Open Issues");
    }
    if (type === "Solve") {
      fetchCves(routeParams.id);
    }
    setShowModal(false);
    setModalData(null);
  };
  useEffect(() => {
    setIsChecked(false);
    setActiveFilters([]);
  }, [type]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div>
          <div>
            {cves?.length > 0 ? (
              <div className="main_container_style ">
                <div className="row">
                  <div className="col-6">
                    <HighlightsRisk
                      highRisk={highRisk}
                      mediumRisk={mediumRisk}
                      lowRisk={lowRisk}
                      onRiskClick={handleRiskClick}
                    />
                  </div>
                  <div className="col-6">
                    <GenericCard
                      children={
                        <IssueStatusChart
                          data={doughnutChartType}
                          options={doughnutChartOptions}
                          onIssueClick={handleIssueClick}
                        />
                      }
                      title={"Issue Status"}
                    />
                  </div>
                </div>
                <div className="mt-4 mb-3">
                  <GroupSwitch
                    id="group-switch"
                    label="Group Issues by Title"
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    className="group-switch"
                  />
                </div>
                <div className="Toast-main">
                  {" "}
                  <ToastContainer />
                </div>
                <OptionsSection
                  cves={filteredCves}
                  filteredCves={filteredCves}
                  tabs={tabs}
                  handleFilterChange={handleFilterChange}
                  activeFilters={activeFilters}
                  removeFilter={removeFilter}
                  handleSortChange={handleSortChange}
                  searchValue={searchValue}
                  handleSearchChange={handleSearchChange}
                  selectedSortValue={selectedSortValue}
                  isChecked={isChecked}
                  selectedItems={selectedItems}
                  onClearSelectRow={handleClearSelectRow}
                  handleActionClick={handleActionClick}
                  actions={actions}
                />
                <CveList
                  isChecked={isChecked}
                  filteredCves={filteredCves}
                  collapsedGroups={collapsedGroups}
                  toggleCollapse={toggleCollapse}
                  SetSelectedItems={setSelectedItems}
                  clearSelectedRows={clearRowsTrigger}
                  handleActionClick={handleActionClick}
                  actions={actions}
                  type={type}
                />
                {showModal && (
                  <GenericActionModal
                    showModal={showModal}
                    handleClose={handleModalClose}
                    onConfirm={onConfirm}
                    actionType={modalData?.actionType}
                    groupData={modalData?.data}
                    type={modalData?.type}
                  />
                )}
              </div>
            ) : type === "history" ? (
              <div className="data-processing-screen-main">
                <Folder className="icon mb-2" />
                <h1 className="mb-2 empty-message-title">No Issues Currently in History</h1>
                <p className="empty-message-description">Resolved, false-positive or acknowledged issues will appear here</p>
              </div>
            )
            : isFinished ? (
              <div className="data-processing-screen-main">
                <NoIssue className="icon mb-2" />
                <h1 className="mb-2">Issue-Free Verification</h1>
                <p>At the last scan, no issues were detected.</p>
              </div>
            ) : (
              <div className="data-processing-screen-main">
                <DataProcess className="icon mb-2" />
                <h1 className="mb-2">Data in Process</h1>
                <p>Hang tight! We're currently processing your data.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default OpenIssues;
