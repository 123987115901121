import React from "react";
import { useParams, useNavigate } from "react-router-dom";

const ComparisonChart = ({ cardsData }) => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const maxValue = Math.max(cardsData?.success, cardsData?.fail || 0);
  const successAttack = (data) => {    
    const serviceTypeToIssueMap = {
      subdomain: "Subdomain Takeover",
      ftp: "Exposed FTP with Default Credentials Discovered",
      ssh: "Exposed SSH with Default Credentials Discovered",
    };
    const issue = serviceTypeToIssueMap[data?.serviceType];
    if (issue && data.success>0) {
      navigate(`/security-issues/${routeParams?.id}?issue=${issue}`);
    }
  };
  
  return (
    <>
      <div className="offensecard_results w-100 justify-content-start">
        <div className="offensecard_results_title mb-2">Results</div>
        <div className="offensecard_results_content w-100">
          <div className="offensecard_results_text">
            <div className="offensecard_success">Attack Succeeded</div>
            <div  className={`${cardsData?.success > 0 ? "underline cursor-pointer" : ""}`} onClick={()=>successAttack(cardsData)}>
              <span className="success_value fw-bold ">
                {cardsData?.success}{" "}
              </span>
              {cardsData?.success === 1 ? "asset is" : "assets are"} vulnerable 
            </div>
            <div className="offensecard_failed">Attack Failed</div>
            <div>
              <span className="failed_value fw-bold">
                {cardsData?.fail || 0}{" "}
              </span>
              {cardsData?.fail === 1 ? "asset is" : "assets are"} not vulnerable
            </div>
          </div>
          <div className="offensecard_chart">
            <div className="bar-graph">
              <div className="bar-container-1" style={{
                display: cardsData?.success > 0 ? "flex" : 'none'
              }}>
                {cardsData?.data1.map((item) => (
                  <div
                    key={item?.value}
                    className="bar1 cursor-pointer"
                    style={{
                      height: `${(cardsData?.success / maxValue) * 100}%`,
                      backgroundColor: item.color,
                      borderRadius: '0px 0px 6px 6px'
                    }}
                    onClick={()=>successAttack(cardsData)}
                  >
                    <span className="bar-value">{cardsData?.success}</span>
                  </div>
                ))}
              </div>
              <div className="bar-container" style={{
                display: cardsData?.fail > 0 ? "flex" : 'none'
              }}>
                {cardsData?.data2.map((item) => (
                  <div
                    key={item?.value}
                    className="bar2"
                    style={{
                      height: `${(cardsData?.fail / maxValue) * 100}%`,
                      backgroundColor: item.color,
                      borderRadius: '6px 6px 0px 0px'
                    }}
                  >
                    <span className="bar-value">{cardsData?.fail}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparisonChart;
